<template>
  <div
    class="memberworkbook"
    :class="{ batchReviews: batchReviews.length > 0 }"
  >
    <div class="main">
      <!-- 右边数据 -->
      <div class="right">
        <div style="height: 100%; background: #fff">
          <template v-if="batchReviews.length === 0">
            <div class="describe_contain">
              <div class="first">
                <div class="tu">
                  <img :src="studentanwer.uphoto" alt="" />
                </div>
                <div class="right2">
                  <div class="name">
                    {{ studentanwer.uname }}
                    <span v-if="studentanwer.student_remarks">
                      ({{ studentanwer.student_remarks }})
                    </span>
                  </div>
                  <div class="time">
                    提交时间：{{
                      studentanwer.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                    }}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;提交来源：《{{
                      studentanwer.course_name
                    }}》{{ studentanwer.schedule_name }}
                  </div>
                </div>
              </div>
              <div>
                <el-button
                  style="color: #ff3535; font-weight: bold"
                  type="text"
                  v-if="is_reviewer && showRemoveDelstuWork"
                  @click="delBookUser"
                >
                  删除重做
                </el-button>
              </div>
            </div>
            <div class="title">作业内容</div>
            <div class="right_top">
              <!-- 未点评的 -->
              <div class="nocomment">
                <div class="student_describe">{{ question.name }}</div>
                <div
                  v-for="item in question.list"
                  :key="item.homework_content_id"
                >
                  <!-- 简答提 -->
                  <template v-if="item.type == 1">
                    <div
                      class="student_describe"
                      v-html="item.content_text"
                    ></div>
                  </template>
                  <template v-if="item.type == 2">
                    <el-image
                      :src="item.url"
                      fit="contain"
                      :preview-src-list="[item.url]"
                      style="height:195px"
                    ></el-image>
                  </template>
                  <template v-if="item.type == 3">
                    <div class="audio reading_audio_wrapper">
                      <audio
                        controls
                        :data-id="`audio1${item.homework_content_id}`"
                        class="audio1"
                        :src="item.url"
                        @play="handlePlay(`audio1${item.homework_content_id}`)"
                      ></audio>
                    </div>
                  </template>
                  <template v-if="item.type == 4">
                    <div
                      class="video teacher-video"
                      @click="previewSrc = item.url"
                    >
                      <img class="poster" :src="item.img" alt="" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="title">学员回答</div>

            <div class="right_top">
              <!-- 未点评的 -->
              <div class="nocomment">
                <!-- 简答提 -->
                <div class="student_describe" v-if="studentanwer.content_text">
                  {{ studentanwer.content_text }}
                </div>
                <div
                  v-for="item in studentanwer.video"
                  :key="item.content_url"
                  class="video"
                  @click="previewSrc = item.content_url"
                ></div>
                <div
                  class="audio reading_audio_wrapper"
                  v-if="studentanwer.audio && studentanwer.audio.length > 0"
                >
                  <audio
                    controls
                    class="audio2"
                    :src="item.content_url"
                    v-for="(item, index) in studentanwer.audio"
                    :key="index"
                    :data-id="`audio2${index}`"
                    @play="handlePlay(`audio2${index}`)"
                  ></audio>
                </div>
                <div
                  v-if="studentanwer.images && studentanwer.images.length > 0"
                >
                  <div class="photos">
                    <ul>
                      <li
                        class="item"
                        v-for="(item, index) in studentanwer.images"
                        :key="index"
                        @click="photoClick(index)"
                        :style="
                          photoselect == index
                            ? 'border: 1px solid #1B9D97'
                            : ''
                        "
                      >
                        <div class="img">
                          <img class="img" :src="item.content_url" alt="" />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="big_photo">
                    <el-image
                      :src="viewImgList.curr"
                      :preview-src-list="viewImgList.list"
                      fit="contain"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="!teacherEdit && isReviewSuccess && batch" class="teacher">
            <div class="teachercomment">
              <div class="teacher_left">
                <div class="t_zi1">老师点评</div>
                <div class="t_zi2">
                  {{ studentanwer.comment.score || '未评分' }}
                  /
                  {{
                    studentanwer.comment.is_show == 1 ? '公开展示' : '私密展示'
                  }}
                </div>
                <div class="t_zi2 ml20" style="color: #ff3535;font-size: 13px;">
                  点评老师：{{ studentanwer.comment.teacher_name }}
                </div>
              </div>
              <div class="teacher_right" v-if="is_reviewer">
                <el-button type="text" @click="editReviewer">
                  编辑点评
                </el-button>
              </div>
            </div>
            <div class="comment_contain">
              {{ studentanwer.comment.comment_content }}
            </div>
            <div class="autoAndimage" style="margin-left:0;">
              <div v-for="item in chunks" :key="item.id" class="flex-center">
                <audio
                  controls
                  :src="item.src"
                  class="mt10 audio3"
                  :data-id="`audio3${item.id}`"
                  @play="handlePlay(`audio3${item.id}`)"
                ></audio>
              </div>
              <p class="mt10"></p>
              <div
                v-for="item in post.list"
                :key="item.id"
                class="flex-center image-contain"
              >
                <div class="name">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div
            class="right_bottom"
            v-if="(!isReviewSuccess || teacherEdit) && is_reviewer"
          >
            <div class="top">
              <div class="left">
                <div class="zi2">评分</div>
                <div class="star">
                  <el-input
                    v-model="post.score"
                    maxlength="10"
                    show-word-limit
                    size="medium"
                    placeholder="请输入评分，选填。如90分、优、良、中、差、A、B、C、D等等"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="red">*</div>
              <div class="zi2">评语</div>
              <el-input
                type="textarea"
                placeholder="请输入点评内容，500个字符以内"
                v-model="post.comment_content"
                maxlength="500"
                :autosize="{ minRows: 4, maxRows: 15 }"
                show-word-limit
              ></el-input>
            </div>
            <div class="autoAndimage">
              <div class="inset-btns">
                <el-button type="text" @click="yuying" :disabled="yuyingval">
                  语音录制
                </el-button>
                <el-button type="text" @click="yingpin">
                  插入音频
                </el-button>
                <el-button type="text" @click="shiping">
                  插入视频
                </el-button>
                <el-button type="text" @click="picture">
                  插入图片
                </el-button>
              </div>

              <div class="speaker-test" v-if="yuyingval">
                <div class="speaker-item">
                  <div class="title2">麦克风</div>
                  <div class="right">
                    <el-select
                      :disabled="isStatr"
                      v-model="micVal"
                      size="medium"
                      @change="switchDevice(arguments[0])"
                    >
                      <el-option
                        v-for="item in audioinput"
                        :key="item.deviceId"
                        :label="item.label"
                        :value="item.deviceId"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="speaker-item">
                  <div class="title2">声波条</div>
                  <div class="right">
                    <div class="volume-contain">
                      <span
                        v-for="item in 30"
                        :key="item"
                        class="volume-item"
                        :class="{ active: item < (volume / 100) * 30 }"
                      ></span>
                    </div>
                  </div>
                </div>
                <div class="flex-center">
                  <el-button
                    type="primary"
                    v-if="isStatr"
                    size="small"
                    @click="stop"
                  >
                    停止录音
                  </el-button>
                  <div class="flex-center" v-else-if="blob">
                    <el-button type="primary" size="small" @click="toplay">
                      播放试听
                    </el-button>
                    <span class="fs12 ml10 mr20">
                      本次录制
                      <span class="redcolor ">{{ duration }}</span>
                      秒,点击播放试听
                    </span>
                    <el-button
                      type="text"
                      class="mr20"
                      @click="resetAudio"
                      size="text"
                    >
                      重新录制
                    </el-button>
                    <el-button type="primary" size="small" @click="uploadAudio">
                      确定并上传
                    </el-button>
                  </div>
                  <el-button v-else type="primary" size="small" @click="start">
                    开始录音
                  </el-button>

                  <span class="fs12 ml10" v-if="isStatr && timerval > 0">
                    录音中...{{ timerval }}秒
                  </span>
                </div>
                <div class="player mt10" ref="player"></div>
              </div>

              <div
                v-for="(item, index) in chunks"
                :key="item.id"
                class="flex-center"
              >
                <audio
                  controls
                  :src="item.src"
                  class="mt10 audio4"
                  :data-id="`audio3${item.id}`"
                  @play="handlePlay(`audio3${item.id}`)"
                ></audio>
                <el-button
                  type="text"
                  class="redcolor ml20 mt10"
                  @click="delAudio(index)"
                >
                  删除
                </el-button>
              </div>
              <p class="mt10"></p>
              <div
                v-for="(item, index) in post.list"
                :key="item.id"
                class="flex-center image-contain"
              >
                <div class="name">{{ item.name }}</div>
                <div
                  class="close el-icon-close"
                  @click="deleteImage(index)"
                ></div>
              </div>
            </div>
            <div class="btns">
              <div class="right">
                <span class="zhanshi">公开展示</span>
                <helpIcon
                  style="margin: 0 20px 0 6px"
                  content="开启后点评的内容对全部学员可见，关闭后只有该学员可见"
                ></helpIcon>
                <el-switch
                  v-model="post.is_show"
                  active-value="1"
                  inactive-value="2"
                ></el-switch>
              </div>

              <div class="btns_right">
                <el-button type="" v-if="teacherEdit" @click="cancelEdit">
                  取消编辑
                </el-button>
                <template v-if="activeName == 1 && batch && !teacherEdit">
                  <el-button size="medium" type="text" @click="next">
                    暂不点评
                  </el-button>
                  <el-button class="btn1" type="" @click="getcomment(true)">
                    发布并下一个
                  </el-button>
                </template>
                <el-button type="primary" @click="getcomment">
                  发布点评
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--视频预览-->
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="previewSrc = ''"
    ></previewVideo>

    <!--本地视频上传 -->
    <uploadfile
      v-if="uploadStart2"
      @uploadConfirmClose="uploadConfirmClose"
      @completelist="completelist"
      @close="uploadConfirmClose"
      :typeselection="2"
      :title="'上传文件'"
      :list="group_id1"
    ></uploadfile>

    <!-- 本地图片/音频上传 -->
    <upload
      v-if="uploadStart"
      @completelist="completelist2"
      :audioType="audioType"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadclose"
      :title="title"
      ref="uploadFile"
      :uploadType="uploadType"
      :files="files"
      :list="group_id1"
    ></upload>

    <workaddpicture
      v-if="status3"
      :dialogstatus.sync="status3"
      @locationUpload="locationUpload"
      :pictureTitle="pictureTitle"
      :type="picktype"
      :size="picksize"
      :count="
        picktype == 1
          ? hadimage.length
          : picktype == 2
          ? hadaudio.length
          : hadvideo.length
      "
      @list="list"
      @audio="audio"
      @video="video"
      ref="workaddpicture"
      @group_id="group_id"
    ></workaddpicture>
  </div>
</template>
<script>
import Recorder from 'recorder-js'
import workaddpicture from './workaddpicture'
import helpIcon from '@/components/helpIcon'
import previewVideo from '@cm/coursePop/previewVideo'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
// import addpicture from '@cm/base/addpicture'

export default {
  naem: 'memberworkbook',
  components: {
    helpIcon,
    upload,
    // addpicture,
    previewVideo,
    workaddpicture,
  },
  props: {
    batchReviews: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      uploadStart2: false,
      group_id1: '',
      status3: false,
      videoElement: [],
      originPost: {
        user_ids: '',
        images: [],
        audio: [],
        video: [],
        comment_content: '',
        is_show: '1',
        score: '',
      },
      post: {
        user_ids: '',
        list: [],
        images: [],
        audio: [],
        video: [],
        comment_content: '',
        is_show: '1',
        score: '',
      },
      previewSrc: '',
      question: {},
      studentanwer: {}, //单个点评-被选各种学员的数据
      commentUserlist: [], //学员列表数据
      is_reviewer: true, //是否是点评老师
      homework_theme_id: '',
      drawer: false, //抽屉控制
      checkList: [], //多选时选中的内容
      batch: true, //是否是批量的状态 true：不是 false：是
      comment: 0, //是否显示点评 0是 1不是
      value: null, //答卷评分
      photoselect: 0, //图像被选中的索引
      input: '', //搜索内容
      activeName: '0', //提交类型切换

      page: 1,
      empty: false,
      isLoadMore: false,

      pagesize: 20,

      teacherEdit: false,
      uploadStart: false,
      addpicturestatus: false,

      uploadOption: {
        group_id1: '',
        // 上传的引入模块1为文档,2图片,3音频
        uploadType: 2,
        // 1图片 2音频
        type: 2,
        size: 10,
        // 1资料库视频上传，可多选 2，是录播课本地添加视频，只能添加单个视频
        typeselection: 1,
        title: '',
      },

      // 音量大小
      volume: 0,
      // 音量控制大小
      volumeControlVal: 30,
      // 麦克风
      micVal: '',
      // 麦克风
      audioinput: [],
      options: {
        audio: true,
        video: false,
      },
      stream: null,
      yuyingval: false,
      mediaRecorder: null,
      timerval: 60,
      isStatr: false,
      chunks: [],
      blob: null,
      duration: 0,
      files: [],
      audioType: 1, //1上传音频 2上传录音
    }
  },

  computed: {
    hadvideo() {
      return this.post.list.filter(item => item.type == 4 || item.video_id)
    },

    hadaudio() {
      return this.post.list.filter(item => item.type == 3 || item.audio_id)
    },

    hadimage() {
      return this.post.list.filter(item => item.type == 2 || item.images_id)
    },

    //删除学生作业
    showRemoveDelstuWork() {
      return this.$store.state.roots.includes(136)
    },

    // 是否批量选中
    checkAll() {
      return this.checkList.length === this.commentUserlist.length
    },

    // 是否已点评
    isReviewSuccess() {
      return (
        this.studentanwer.comment && this.studentanwer.comment.comment_time > 0
      )
    },

    // 默认选中的图片 和 图片列表
    viewImgList() {
      let res = {
        curr: '',
        list: [],
      }
      // 默认选中一张图片
      if (this.studentanwer.images && this.studentanwer.images.length > 0) {
        res = {
          curr: this.studentanwer.images[this.photoselect].content_url,
          list: this.studentanwer.images.map(item => item.content_url),
        }
      }

      return res
    },
  },

  watch: {
    input() {
      this.page = 1
      this.commentUserlist = []
    },

    activeName() {
      this.reset()
    },

    // 监听批量事件
    batch(val) {
      // 还原到默认的定位 批量导致的
      if (this.originActive && val) {
        this.activeName = this.originActive
        this.originActive = undefined
      }
    },
  },

  created() {
    // this.initAudioNode()
    // this.audioStop()
  },

  mounted() {
    this.videoElement = document.getElementsByTagName('audio')

    this.homework_theme_id = this.$route.params.id

    // 如果不是批量点评
    if (this.batchReviews.length === 0) {
      this.uid = this.$route.params.uid
      this.selectStudent({
        user_id: this.uid,
        homework_theme_id: this.homework_theme_id,
      })
    } else {
      this.uid = this.batchReviews
    }
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  destroyed() {
    this.destory()
  },

  methods: {
    // audioStop() {
    //   var audios = document.getElementsByTagName('audio')
    //   Array.from(audios).forEach(item => {
    //     // 监听某一个正在播放，停止其他audio元素
    //     item.addEventListener('play', function() {
    //       Array.from(audios).forEach(i => {
    //         i !== this && i.pause()
    //       })
    //     })
    //     // 播放结束重置时间 需要重新播放加 item.play()
    //     item.addEventListener(
    //       'ended',
    //       () => {
    //         item.currentTime = 0
    //         // item.play()
    //       },
    //       false
    //     )
    //   })
    // },

    completelist2(val) {
      if (this.audioType == 2) {
        this.chunks.push({ id: Math.random(), src: val.url })
      }
    },

    // 删除已经录制的音频
    delAudio(index) {
      this.chunks.splice(index, 1)
    },

    async transToFile(blob, fileName, fileType) {
      console.log(blob)
      return new window.File([blob], fileName, { type: fileType })
    },

    uploadAudio() {
      this.title = '上传音频'
      this.uploadType = 3
      this.audioType = 2
      let textContain = this.transToFile(
        this.blob,
        `录音文件_${this.homework_theme_id}_${Date.now()}.mp3`,
        'audio/mpeg'
      )

      // 转换完成后可以将file对象传给接口
      textContain.then(res => {
        this.files.push(res)
        console.log(res, this.blob) // 开始上传
      })

      this.uploadStart = true

      this.$nextTick(() => {
        // console.log(this.$refs.uploadFile.$refs.uploadTextFile)
        this.$refs.uploadFile.$refs.uploadTextFile.addFile({
          target: { files: this.files },
        })
      })

      this.files = []
      // this.fatherFS = {}
    },

    yuying() {
      if (this.yuyingval) {
        this.yuyingval = !this.yuyingval
      } else {
        this.init()
      }
    },

    async start() {
      await this.mediaRecorder.start()
      this.isStatr = true
      this.timer = setInterval(() => {
        // 倒计时结束,自动停止录音
        if (this.timerval == 0) {
          this.stop()
        } else {
          this.timerval--
        }
      }, 1000)
      console.log('录音中...')
    },

    // 停止录音
    async stop() {
      this.isStatr = false
      clearInterval(this.timer)
      await this.mediaRecorder.stop().then(({ blob }) => {
        this.blob = blob
        this.audioNew()
      })
    },

    audioNew() {
      var soundClips = this.$refs.player
      var clipContainer = document.createElement('article')
      var audio = document.createElement('audio')

      clipContainer.classList.add('clip')
      audio.classList.add('audioclass')
      audio.setAttribute('controls', '')
      const id = Math.random()
      audio.dataset.id = `audio4${id}`
      audio.addEventListener(
        'play',
        () => {
          this.handlePlay(`audio4${id}`)
        },
        false
      )
      // audio.setAttribute('type', '')

      clipContainer.appendChild(audio)
      soundClips.appendChild(clipContainer)

      audio.controls = true
      this.audioURL = URL.createObjectURL(this.blob)
      audio.src = this.audioURL

      this.countAudioTime()
    },

    // 重新开始录音
    resetAudio() {
      this.blob = null
      this.timerval = 60
      this.duration = 0
      if (this.$refs.player) {
        this.$refs.player.innerHTML = ''
      }
    },

    // 计算音频的时长
    async countAudioTime() {
      const audio = document.querySelector('.audioclass')
      while (isNaN(audio.duration) || audio.duration === Infinity) {
        // 延迟一会 不然网页都卡死
        await new Promise(resolve => setTimeout(resolve, 200))
        // 设置随机播放时间，模拟调进度条
        audio.currentTime = 10000000 * Math.random()
        audio.currentTime = 0
      }
      this.duration = Math.floor(audio.duration)
    },

    toplay() {
      document.querySelector('.audioclass').play()
    },

    async init() {
      await this.getUserMedia()
      this.enumerateDevices()
      this.onEvent()
    },

    // 获取授权和流
    async getUserMedia() {
      try {
        if (!this.mediaRecorder) {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)()
          const recorder = new Recorder(audioContext, {
            onAnalysed: () => {},
          })
          this.mediaRecorder = recorder
        }

        // 获取用户的 media 信息
        // await navigator.mediaDevices
        //   .getUserMedia(this.options)
        //   .then(stream => {
        //   })
        //   .catch(err => console.log('Uh oh... unable to get stream...', err))

        this.stream = await navigator.mediaDevices.getUserMedia(this.options)
        this.mediaRecorder.init(this.stream)
        this.yuyingval = true
        this.audioContext = new AudioContext()
        // 音频音量大小
        this.onInputAudioProcess()
      } catch (error) {
        this.onError(error)
      }
    },

    // 获取设备
    async enumerateDevices() {
      // 麦克风
      this.audioinput = []
      const devices = await navigator.mediaDevices.enumerateDevices()
      devices.forEach(device => {
        if (
          device.deviceId !== 'communications' &&
          device.kind == 'audioinput'
        ) {
          this.audioinput.push(device)
        }
      })
      console.log(this.audioinput)
      this.$nextTick(() => {
        if (this.stream) {
          // 获取当前音频轨道
          let audioTracks = this.stream.getAudioTracks()
          audioTracks = audioTracks && audioTracks[0] ? audioTracks[0] : [{}]
          // 麦克风
          const micVal = this.audioinput.find(
            item => item.label === audioTracks.label
          )
          this.micVal = micVal ? micVal.deviceId : this.micVal
        }
      })
    },

    // 音频音量大小
    onInputAudioProcess() {
      // 将麦克风的声音输入这个对象
      const mediaStreamSource = this.audioContext.createMediaStreamSource(
        this.stream
      )
      // 创建一个音频分析对象，采样的缓冲区大小为4096，输入和输出都是单声道
      const scriptProcessor = this.audioContext.createScriptProcessor(
        4096,
        1,
        1
      )
      // 将该分析对象与麦克风音频进行连接
      mediaStreamSource.connect(scriptProcessor)
      // 将该分析对象与麦克风音频进行连接
      scriptProcessor.connect(this.audioContext.destination)
      // 开始处理音频
      scriptProcessor.onaudioprocess = _.throttle(e => {
        // 获得缓冲区的输入音频，转换为包含了PCM通道数据的32位浮点数组
        const buffer = e.inputBuffer.getChannelData(0)
        // 获取缓冲区中最大的音量值
        const maxVal = Math.max.apply(Math, buffer)
        // 显示音量值
        this.volume = Math.round(maxVal * 100)
      }, 100)
    },

    onEvent() {
      navigator.mediaDevices.ondevicechange = _.debounce(() => {
        console.log('设备更新')
        this.destory()
        this.init()
      }, 1000)
      // this.stream.onaddtrack = () => {}
      // this.stream.onremovetrack = () => {}
    },

    // 出现错误
    onError(error) {
      switch (error.name) {
        case 'NotAllowedError':
          this.$alert('麦克风无权限访问', '温馨提示', {
            confirmButtonText: '我知道了',
          })
          break
        case 'NotFoundError':
          if (this.options.video) {
            this.options = {
              video: false,
              audio: true,
            }
            this.init()
          } else {
            this.$alert('请检查您的麦克风设备是否正常', '温馨提示', {
              confirmButtonText: '我知道了',
            })
          }
          break
      }
      console.info(error.code, error.message, error.name)
    },

    // 初始化 预先加载
    // initAudioNode() {
    //   this.audioNode = new Audio()
    //   this.audioNode.autoplay = true
    //   this.audioNode.preload = 'auto'
    //   this.setSrc()
    //   this.setAudioVolume()
    // },

    // // 设置音频地址
    // setSrc(src = 'https://f.dingdingkaike.com.cn/test/audio_test.mp3') {
    //   this.audioNode.src = src
    // },

    // // 设置音频音量
    // setAudioVolume() {
    //   this.audioNode.volume = parseFloat(this.volumeControlVal / 100)
    // },

    // 切换设备
    switchDevice(deviceId) {
      console.log('切换设备', deviceId)
      this.destory()
      this.options = {
        video: false,
        audio: {
          deviceId: {
            exact: deviceId,
          },
        },
      }
      this.init()
    },

    // 卸载
    destory() {
      this.options = {
        audio: true,
        video: false,
      }

      if (this.audioContext) {
        this.audioContext.close()
        this.audioContext = null
      }

      if (this.stream) {
        const tracks = this.stream.getTracks()

        tracks.forEach(function(track) {
          track.stop()
        })
      }

      this.stream = null
    },

    // 2本地上传图片 3视频
    locationUpload() {
      if (this.picktype == 3) {
        this.uploadStart2 = true
      } else {
        this.uploadStart = true
      }
    },

    uploadclose() {
      this.uploadStart = false
      this.uploadStart2 = false
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      if (this.$refs.workaddpicture) {
        this.$refs.workaddpicture.renew()
      }
      this.destory()
      this.audioType = 1
      this.stream = null
      this.yuyingval = false
      this.mediaRecorder = null
      this.timerval = 60
      this.isStatr = false
      this.blob = null
      this.duration = 0
      this.files = []
      this.audioURL = null
      this.yuyingval = false

      this.uploadStart = false
      this.uploadStart2 = false
    },

    //获取插入图片,视频,音频中选择的分组id
    group_id(val) {
      this.group_id1 = val
    },

    // 插入图片的数据
    list(val) {
      val.forEach(item => {
        this.post.list.push({
          id: Math.random(),
          type: 2,
          url: item.url,
          name: item.name,
          source_id: item.images_id,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })
      this.forlist()
    },

    //插入音频得数据
    audio(val) {
      val.forEach(item => {
        this.post.list.push({
          id: Math.random(),
          type: 3,
          url: item.url,
          source_id: item.audio_id,
          name: item.name,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })

      this.forlist()
    },

    //插入视频的数据
    video(val1) {
      val1.forEach(val => {
        this.post.list.push({
          id: Math.random(),
          name: val.file_name,
          type: 4,
          url: val.video_url,
          source_id: val.video_id,
          img: val.video_img,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })
      this.forlist()
    },

    forlist() {
      this.post.list = this.hadaudio.concat(this.hadvideo, this.hadimage)
    },

    //插入图片弹窗
    picture() {
      this.title = '上传图片(多选)'
      this.uploadType = 2
      this.pictureTitle = '插入图片(多选)'
      this.picktype = 1
      this.picksize = 20
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    //插入音频弹窗
    yingpin() {
      this.title = '上传音频(多选)'
      this.uploadType = 3
      this.pictureTitle = '插入音频(多选)'
      this.picktype = 2
      this.picksize = 100
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    //插入视频弹窗
    shiping() {
      this.title = '上传视频(多选)'
      this.uploadType = 4
      this.pictureTitle = '插入视频(多选)'
      this.picktype = 3
      this.picksize = 100
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    handlePlay(id) {
      this.videoElement = document.getElementsByTagName('audio')
      // console.log('aaaa', id, this.videoElement)
      Array.from(this.videoElement).forEach(item => {
        // console.log('bbbb', item.dataset.id)
        if (item.dataset.id == id) {
          item.play()
        } else {
          item.pause()
        }
      })
    },

    reset() {
      this.resetOption()
      this.resetAudio()
      this.yuyingval = false
    },

    // 清除选中状态 数据 和 答案
    resetOption() {
      this.page = 1
      this.batch = true
      this.drawer = false
      this.teacherEdit = false
      this.checkList = []
      this.commentUserlist = []
      this.studentanwer = {}
      this.post = Object.assign({}, this.originPost)
    },

    // 图片选择
    photoClick(val) {
      this.photoselect = val
    },

    // 编辑点评
    editReviewer() {
      this.teacherEdit = Object.assign({}, this.post)
    },

    // 取消编辑
    cancelEdit() {
      this.selectStudent({
        user_id: this.uid,
        homework_theme_id: this.homework_theme_id,
      })

      this.teacherEdit = false
    },

    // 学员选择
    selectStudent(val) {
      this.$http({
        url: '/Homework/resultContent',
        data: {
          user_id: val.user_id,
          homework_theme_id: this.homework_theme_id,
        },
        callback: ({ data, find }) => {
          // 如果是编辑状态 重置一下
          this.teacherEdit = false
          this.question = find

          // 重置图片选择index为0
          this.photoselect = 0

          this.studentanwer = Object.assign({}, val, data)

          this.post = _.assign({}, data.comment, {
            list: [],
          })

          if (Object.keys(data.comment).length > 0) {
            this.post.list = data.comment.audio.concat(
              data.comment.video,
              data.comment.images
            )
            this.chunks = data.comment.record || []
          }

          this.post.list = _.assign(
            [],
            this.post.list.map(item => {
              return {
                ...item,
                id: Math.random(),
                name: item.file_name || item.name,
              }
            })
          )

          // 如果点击的学员没有被选中 那就是单选 否则是多选状态
          this.post.user_ids = val.user_id
        },
      })
    },

    // 点评/批量点评
    getcomment() {
      if (this.post.comment_content || this.post.list.length > 0) {
        this.$http({
          url: '/Homework/comment',
          data: _.assign({}, this.post, {
            user_ids: this.uid,
            homework_theme_id: this.homework_theme_id,
            record: this.chunks,
            images: this.hadimage,
            video: this.hadvideo,
            audio: this.hadaudio,
          }),
          callback: ({ code }) => {
            if (code == 200 && this.batchReviews.length === 0) {
              this.selectStudent({
                user_id: this.uid,
                homework_theme_id: this.homework_theme_id,
              })
              this.reset()
            } else {
              this.$emit('close')
            }
          },
        })
      } else {
        this.$root.prompt('请先输入点评内容')
      }
    },

    // 删除作业
    delBookUser() {
      this.$confirm(
        '确定后，学员已提交的内容会被删除，不可恢复！学员可重新提交作业。',
        '删除重做',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: '/Homework/delBookUser',
          data: {
            user_id: this.studentanwer.user_id,
            homework_theme_id: this.homework_theme_id,
          },
          callback: () => {
            this.$router.push(
              `/courseWorkbook/studentWorkbook/${this.homework_theme_id}`
            )
          },
        })
      })
    },

    uploadConfirm() {
      if (this.post.images.length >= 10) {
        this.$root.prompt('插入的内容数量最多10个！')
        return
      }
      this.uploadOption = {
        group_id1: '',
        // 上传的引入模块1为文档,2图片,3音频
        uploadType: 2,
        // 1图片 2音频
        type: 1,
        size: 10,
        // 1资料库视频上传，可多选 2，是录播课本地添加视频，只能添加单个视频
        typeselection: 1,
        pictureTitle: '添加图片(单选)',
        title: '上传图片',
      }
      this.addpicturestatus = !this.addpicturestatus
    },

    // 插入音频
    uploadConfirm2() {
      if (this.post.audio.length >= 10) {
        this.$root.prompt('插入的内容数量最多10个！')
        return
      }
      this.uploadOption = {
        group_id1: '',
        // 上传的引入模块1为文档,2图片,3音频
        uploadType: 3,
        // 1图片 2音频
        type: 2,
        size: 10,
        // 1资料库视频上传，可多选 2，是录播课本地添加视频，只能添加单个视频
        typeselection: 1,
        pictureTitle: '添加音频(单选)',
        title: '上传音频',
      }
      this.addpicturestatus = !this.addpicturestatus
    },

    // 插入视频
    uploadConfirm3() {
      if (this.post.video.length >= 10) {
        this.$root.prompt('插入的内容数量最多10个！')
        return
      }
    },

    uploadComplete(val) {
      this.post.images.push(val)
    },

    audioComplete(val) {
      this.post.audio.push(val)
    },

    //本地上传弹窗控制
    localUpload() {
      this.uploadStart = !this.uploadStart
    },

    //获取图片上传中的分组id
    groupIdChange(val) {
      this.uploadOption.group_id1 = val
    },

    // 删除图片
    deleteImage(index) {
      this.post.list.splice(index, 1)
    },
  },
}
</script>

<style lang="less">
.el-notification__title {
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.audio {
  margin-top: 10px;
  display: block;
}
</style>
<style lang="scss" scoped>
.video {
  width: 120px;
  height: 68px;
  cursor: pointer;
  background: #000 url('~@ass/img/1.4.0.4/icon_bfann5@2x.png') no-repeat center;
  background-size: 36px;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
  &.teacher-video {
    width: 347px;
    height: 195px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      width: 120px;
      height: 68px;
      transform: translate(-50%, -50%);
      background: url('~@ass/img/1.4.0.4/icon_bfann5@2x.png') no-repeat center;
    }
    .poster {
      width: 100%;
      display: block;
    }
  }
}
.bigpictrue {
  position: absolute;
  cursor: pointer;
  width: 32%;
  height: 30px;
  top: 118px;
  left: 47px;
  overflow: hidden;
  ::v-deep .el-image__inner {
    opacity: 0;
  }
}
.memberworkbook {
  padding: 20px 20px 50px 20px;
  background-color: #f5f5f5;
  &.batchReviews {
    padding: 0;
    .main {
      padding: 0;
    }
  }
  ::v-deep .el-drawer {
    width: 500px !important;
    .el-drawer__header {
      margin-bottom: 0px;
    }
  }
  .describe_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .first {
      display: flex;
      align-items: center;
      .tu {
        width: 38px;
        height: 38px;
        min-width: 38px;
        border-radius: 3px;
        margin-right: 14px;
        img {
          border-radius: 3px;
          width: 100%;
          height: 100%;
        }
      }
      .right2 {
        .name {
          font-size: 14px;
          color: #333333;
          line-height: 16px;
        }
        .time {
          font-size: 12px;
          color: #999999;
          margin-top: 13px;
          line-height: 16px;
        }
      }
    }
  }
  .right_top {
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .hadcomment {
      .answer {
        font-size: 14px;
        color: #333333;
        line-height: 20px;

        p {
          margin-bottom: 15px;
        }
      }
    }
    .student_describe {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-bottom: 20px;
      word-break: break-all;
    }
    .audio {
      width: 100%;
      display: block;
      margin-bottom: 10px;
      audio {
        margin-bottom: 10px;
        min-width: 100%;
        height: 36px;
        display: block;
      }
    }
    .photos {
      margin-bottom: 20px;
      ul {
        display: flex;
        .item {
          width: 40px;
          height: 40px;
          cursor: pointer;
          background-color: #f5f5f5;
          box-sizing: border-box;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          .img {
            height: 100%;
            width: 100%;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
        .item:last-child {
          margin: 0;
        }
      }
    }
    .moreselect_class {
      font-size: 14px;
      color: #333333;
      margin-bottom: 17px;
      line-height: 20px;
    }
    .big_photo {
      width: 100%;
      height: 352px;
      margin-bottom: 10px;
      display: flex;
    }
  }
  .main {
    background-color: #fff;
    padding: 20px;
    .left {
      box-sizing: border-box;
      background-color: #fff;
      width: 360px;
      margin-right: 20px;
      position: relative;
      ::v-deep .el-tabs__nav-scroll {
        padding: 0 84px;
        // #tab-1 {
        //   padding-right: 83px;
        // }
      }
      .search {
        margin: 8px 20px 20px;
        display: flex;
        align-items: center;
      }
      .contain {
        box-sizing: border-box;
        ul {
          max-height: 1026px;
          overflow: auto;
          li {
            cursor: pointer;
            overflow: hidden;
            padding: 11px 28px 11px 40px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            .img {
              width: 28px;
              min-width: 28px;
              height: 28px;
              border-radius: 2px;
              margin-right: 14px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              color: #333333;
              line-height: 16px;
            }
          }
        }
        ::v-deep .moreselect {
          padding-bottom: 54px;
          .el-checkbox {
            margin-left: 20px;
            display: flex;
            align-items: center;
          }
          .el-checkbox__label {
            padding: 0;
            overflow: hidden;
          }
          li {
            padding-left: 14px;
          }
        }
      }

      ::v-deep .allselect {
        z-index: 100;
        padding: 9px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 54px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.07),
          0px 1px 0px 0px rgba(210, 210, 210, 0.5);
        .el-checkbox__label {
          padding-left: 20px !important;
        }
      }
    }
    .right {
      position: relative;
      // width: calc(100% - 380px);
      .enpty {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        .title {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 14px;
        }
        .describe {
          padding: 150px 0;
          text-align: center;
          font-size: 14px;
          color: #333333;
          line-height: 14px;
        }
      }
      .hadAddstudentList {
        padding: 20px;
        .titlelist {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 14px;
        }
        .ul_list {
          box-sizing: border-box;
          padding: 20px 0;
          padding-bottom: 360px;
          li {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tu_name {
              width: calc(100% - 114px);
              display: flex;
              align-items: center;
              .tu {
                width: 28px;
                height: 28px;
                border-radius: 2px;
                margin-right: 14px;
                img {
                  border-radius: 2px;
                  width: 100%;
                  height: 100%;
                }
              }
              .name {
                width: calc(100% - 28px);
                font-size: 14px;
                color: #333333;
                line-height: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .btn {
              width: 114px;
              .span_1 {
                color: $color;
                height: 14px;
                margin: 0 10px;
              }
            }
          }
        }
      }
      .teacher {
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        .teachercomment {
          display: flex;
          padding-bottom: 10px;
          align-items: center;
          justify-content: space-between;
          .teacher_left {
            display: flex;
            align-items: center;
            .t_zi1 {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              line-height: 14px;
              margin-right: 14px;
            }
            .t_zi2 {
              font-size: 12px;
              color: #666666;
              line-height: 12px;
            }
          }
        }

        .comment_contain {
          margin-bottom: 20px;
          min-height: 180px;
          background: #f5f5f5;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
      }

      .right_bottom {
        width: 100%;
        background-color: #fff;
        .top {
          padding: 20px 0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 0;
            .zi2 {
              margin-right: 27px;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              white-space: nowrap;
            }
            .star {
              flex: 1;
            }
          }
        }
        .bottom {
          padding: 20px 0 0;
          padding-top: 0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .zi2 {
            margin-right: 20px;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            white-space: nowrap;
          }
          .red {
            font-size: 14px;
            margin-right: 3px;
          }
        }
        .btns {
          display: flex;
          align-items: center;
          justify-content: right;
          padding: 0 0 20px;
          box-sizing: border-box;
          .checklist {
            font-size: 12px;
            color: #333333;
            line-height: 12px;
          }
          .btns_right {
            display: flex;
            align-items: center;
            .el-button {
              border-radius: 4px;
              width: 126px;
            }
            .btn1 {
              &.el-button {
                border: 1px solid #1b9d97;
                color: #1b9d97;
              }
            }
          }
          .right {
            display: flex;
            align-items: center;
            margin-right: 20px;
            .zhanshi {
              font-size: 12px;
              color: #4a4a4a;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
.left-empty {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  text-align: center;
  padding: 120px 0;
}
.load-more {
  font-size: 12px;
  font-weight: bold;
  color: #1b9d97;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}
.autoAndimage {
  margin-left: 76px;
  .speaker-test {
    margin-top: 20px;
    .speaker-item {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .title2 {
        font-size: 12px;
        min-width: 36px;
        margin-right: 28px;
      }
      ::v-deep .right {
        width: 476px;
        .el-select {
          border: 0;
          width: 100%;
          // .el-input__inner {
          //   border: 0;
          //   width: 100%;
          //   display: block;
          //   height: 34px;
          //   font-size: 12px;
          //   color: #fff;
          //   background: #47494e;
          //   border-radius: 2px;
          // }
          // .el-input__icon {
          //   line-height: 34px;
          // }
        }
        .speaker-play {
          border: 0;
          width: 152px;
          height: 34px;
          color: #fff;
          font-size: 12px;
          background: #47494e;
          border-radius: 4px;
          &:hover {
            background: #fa6400;
          }
        }
        .volume-control {
          display: flex;
          align-items: center;
          .icon {
            width: 16px;
            height: 13px;
            background: url('~@ass/img/1.4.5/icon_ypyl.svg') no-repeat center;
          }
          .slider {
            width: 200px;
            margin-left: 20px;
            transform: scale(0.7);
            transform-origin: left center;
            .el-slider__bar {
              background-color: #fa6400;
            }
            .el-slider__button {
              border-color: #fff;
              background-color: #fff;
            }
          }
        }
        .video {
          width: 220px;
          height: 124px;
          position: relative;
          video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            background: #47494e;
          }
          .video-error {
            width: 60px;
            height: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('~@ass/img/1.4.5/icon_sbjc_wsxt.svg') no-repeat
              center;
          }
        }
        .volume-contain {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .volume-item {
            width: 6px;
            height: 20px;
            background: #ececec;
            border-radius: 3px;
            &.active {
              background: #fa6400;
            }
          }
        }
      }
    }
  }
  .inset-btns {
    .el-button {
      margin-right: 30px;
      &.disable {
        filter: grayscale(1);
      }
    }
  }
  .image-contain,
  .audio-contain {
    margin-bottom: 10px;
    .name {
      font-size: 14px;
      color: #666666;
      margin-right: 20px;
    }
    .close {
      color: #999999;
      cursor: pointer;
    }
  }
}
.title {
  font-size: 14px;
  color: #333333;
  margin-bottom: 20px;
}
</style>
